<div
  class="wrapper fixed inset-0 bg-white flex items-center justify-center z-50 flex-col"
  *ngIf="isLoading"
  [ngClass]="{
    'loader-small': options.loaderSize === LoaderSize.Small,
    'loader-large': options.loaderSize === LoaderSize.Large,
    'bg-opacity-25 backdrop-blur-sm backdrop-filter': !options.solidBackground
  }">
  <div class="loader-wrapper">
    <div class="loader-logo">
      <img src="assets/images/app-mark.png" alt="logo" />
    </div>
    <div class="loader"></div>
  </div>
  <span class="loader-text" *ngIf="loadingText">{{ loadingText }}</span>
</div>
