<div
  class="flex justify-between w-full p-2 mb-2 rounded bg-accentLight-50 dark:text-black dark:bg-gray-300">
  <div class="flex flex-wrap gap-1 items-center">
    <p *ngIf="title" class="font-bold m-0">{{ title }}</p>
    <ng-content></ng-content>
  </div>
  <div class="flex items-center gap-4">
    <ng-content select="[snackbarAction]"></ng-content>
    <button
      mat-icon-button
      (click)="snackbarClosed.emit()"
      matTooltip="Close this messsge">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
