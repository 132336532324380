import { AccountQuery, AccountService } from '@app-state/account';
import { Injectable, OnDestroy } from '@angular/core';
import { SubscriptionManager } from '@app-utils/subscription-manager';
import { CurrentUserClient } from '@app-clients/people/current-user.client';
import { SessionStore } from '@app-state/session/session.store';
import { Account } from '@app-models/account/account';
import { CreatePerson } from '@app-models/people/create-person';
import { FreezeUi } from '@app-services/ui/freeze-ui.service';
import { SessionPersistenceManager } from './session-persistence';
import { UserService } from '@app-state/user';
import { AssessmentService } from '@app-state/assessment';
import { NotificationCenter } from '@app-services/ui/notification-center.service';
import { NotificationDisplayType } from '@app-models/notification/notification-display-type';

/**
 * Service class for manipulating session data
 */
@Injectable()
export class SessionService implements OnDestroy {
  private readonly _subscriptions = new SubscriptionManager();
  private readonly _sessionTimoutSeconds = 30000;
  private _unfreezeLocked: boolean = false;

  private _isInitialized = false;

  /**
   * Indicates whether session has been initialized or not
   */
  get isInitialized(): boolean {
    return this._isInitialized;
  }

  constructor(
    private readonly _accountService: AccountService,
    private readonly _userService: UserService,
    private readonly _sessionStore: SessionStore,
    private readonly _accountQuery: AccountQuery,
    private readonly _currentUserClient: CurrentUserClient,
    private readonly _assessmentService: AssessmentService,
    private readonly _freeze: FreezeUi,
    private readonly _notification: NotificationCenter,
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribeAll();
  }

  /**
   * Updates logged in user information
   */
  async updateUserInformation(person: CreatePerson): Promise<boolean> {
    try {
      const updatedUser = await this._currentUserClient.update(person);
      this._sessionStore.update({ user: updatedUser });
      await this._userService.reloadUsers();
      return true;
    } catch (_) {
      return false;
    }
  }
  /**
   * Starts a new session. This can be only called once per application.
   */
  async startSession(): Promise<void> {
    if (this._isInitialized) return;

    const timeoutId = this.startTimeout();

    try {
      this.freezeUi();
      await this.loadAccounts();
      await this._assessmentService.loadAssessments();

      // Seal the instance
      this.sealSession();

      this._subscriptions.add(
        this._accountQuery
          .selectActive()
          .subscribe(this.activeAccountUpdated.bind(this)),
      );
      this.unfreezeUi();
    } catch (error) {
      this.displayLoadError();
    }

    clearTimeout(timeoutId);
  }
  private async loadAccounts(): Promise<void> {
    await this._accountService.loadAccounts();
    // Must load accounts first - need this for the current user HTTP call
    this.selectInitialAccount();
    const user = await this._currentUserClient.getUser();
    // Update the state
    this._sessionStore.update({
      account: this._accountQuery.getActive(),
      user,
    });
  }
  private async activeAccountUpdated(account: Account): Promise<void> {
    const currentAccount = this._sessionStore.getValue().account;
    if (
      !account ||
      (currentAccount && account.accountId === currentAccount.accountId)
    )
      return;

    this.freezeUi();

    SessionPersistenceManager.update({ accountId: account.accountId });

    location.href = '/';
  }
  private selectInitialAccount(): void {
    const account = this._accountQuery.getEntity(
      SessionPersistenceManager.load().accountId,
    );

    if (account) {
      this._accountService.selectAccount(account);
    } else if (this._accountQuery.getCount() > 0) {
      this._accountService.selectAccount(this._accountQuery.getAll()[0]);
    }
  }
  private freezeUi(): void {
    this._freeze.freezeUi('Loading...', {
      solidBackground: true,
    });
  }
  private unfreezeUi(): void {
    if (this._unfreezeLocked) return;

    this._freeze.unfreezeUi();
  }
  private sealSession(): void {
    this._isInitialized = true;
  }

  /**
   * Displays a timeout dialog if loading takes too long
   */
  private startTimeout(): number {
    const start = Date.now();

    const timeoutId = setTimeout(() => {
      const elapsedTime = Date.now() - start;
      if (elapsedTime >= this._sessionTimoutSeconds) {
        this._unfreezeLocked = true;
        this.displayLoadError();
        clearTimeout(timeoutId);
      }
    }, this._sessionTimoutSeconds);

    return timeoutId;
  }

  private displayLoadError(): void {
    this._notification.error(
      'The application could not be loaded.',
      NotificationDisplayType.ApiUnavailableModal,
    );
  }
}
