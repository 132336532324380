/**
 * Persistence model for session
 */
interface SessionPersistence {
  accountId: number;
}

/**
 * Gets the default persistence model
 */
function defaultSessionPersistence(): SessionPersistence {
  return {
    accountId: 0,
  };
}

/**
 * Class for managing persisted session state
 */
export class SessionPersistenceManager {
  private static readonly PERSIST_KEY = 'hm-session';

  /**
   * Updates the persisted store
   * @param session Representation of the session
   */
  static update(session: Partial<SessionPersistence>): void {
    const newState: SessionPersistence = {
      ...SessionPersistenceManager.load(),
      ...session,
    };
    localStorage.setItem(
      SessionPersistenceManager.PERSIST_KEY,
      JSON.stringify(newState),
    );
  }

  /**
   * Load the persisted store
   */
  static load(): SessionPersistence {
    const json = localStorage.getItem(SessionPersistenceManager.PERSIST_KEY);

    try {
      return JSON.parse(json) ?? defaultSessionPersistence();
    } catch (_) {
      return defaultSessionPersistence();
    }
  }
}
