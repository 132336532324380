import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cp-cus-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent {
  @Input({ required: true }) title: string = '';

  @Output() readonly snackbarClosed: EventEmitter<void> =
    new EventEmitter<void>();
}
