import { QueryEntity } from '@datorama/akita';
import { UserState } from '@app-state/user/user.state';
import { Injectable } from '@angular/core';
import { UserStore } from '@app-state/user/user.store';
import { Observable } from 'rxjs';
import { UserFilter } from '@app-models/user/user-filter';
import { nameof } from 'ts-simple-nameof';
import { Person } from '@app-models/people/person';

/**
 * Class to query data from the user store.
 */
@Injectable()
export class UserQuery extends QueryEntity<UserState> {
  constructor(_userStore: UserStore) {
    super(_userStore);
  }

  /**
   * Selects whether the users are fully loaded - no more data can come from the server.
   */
  selectFullyLoaded(): Observable<boolean> {
    return this.select(state => state.fullyLoaded);
  }

  /**
   * Selects the current filter for users.
   */
  selectFilter(): Observable<UserFilter> {
    return this.select(state => state.ui.filter);
  }
  /**
   * Gets the current value of filter for users.
   */
  getFilter(): UserFilter {
    return this.getValue().ui.filter;
  }

  /**
   * Checks whether any filter is set
   */
  hasFilter(): boolean {
    const value = this.getFilter();

    return value && Object.keys(value).length > 0;
  }
  /**
   * Indicates whether any advanced filters are set
   */
  hasAdvancedFilter(): boolean {
    const setProperties = Object.getOwnPropertyNames(this.getValue().ui.filter);

    return setProperties.some(p => p !== nameof<Person>(p => p.nameFull));
  }
}
