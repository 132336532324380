import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { Injectable } from '@angular/core';
import { Assessment, AssessmentRevision } from '@app-models/assessment';
import { AssessmentVariant } from '@app-models/assessment/assessment-variant';
import { ApiResponse } from '@app-responses/api-response';
import { ExtractCompletedRevisionCount } from '@app-models/assessment/extract-data/extract-completed-revision-count';
import { convertDateToDateTime } from '@app-utils/helpers/date-convertors';
import { AssessmentCompleteBetween } from '@app-models/assessment/extract-data/assessment-complete-between';

@Injectable()
export class AssessmentClient extends BaseClient {
  constructor(httpClient: HttpClient, appEnv: AppEnvironment) {
    super('assessment', httpClient, appEnv);
  }

  async getAssessments(accountId: number): Promise<Assessment[]> {
    const response = await this.get<ApiResponse<Assessment[]>>([accountId]);
    const families = response.data;

    this.normalizeDates(families);

    return families;
  }

  async getAssessmentForParticipantAssessmentRevision(
    startCode: string,
  ): Promise<Assessment> {
    const assessment = await this.get<Assessment>([startCode]);
    this.normalizeDates([assessment]);

    return assessment;
  }

  async getCompletedRevisionCount(
    completeRevisionData: AssessmentCompleteBetween,
  ): Promise<number> {
    const completedRevisionData = await this.get<
      ApiResponse<ExtractCompletedRevisionCount>
    >(
      ['assessmentsCompletedBetweenDates'],
      [
        {
          assessmentRevisionId:
            completeRevisionData.assessmentRevisionId.toString(),
        },
        {
          completeBetweenStart: convertDateToDateTime(
            completeRevisionData.from,
          ),
        },
        {
          completeBetweenEnd: convertDateToDateTime(
            completeRevisionData.through,
            false,
          ),
        },
      ],
    );

    return completedRevisionData.data.assessmentsCompleted;
  }

  private normalizeDates(assessments: Assessment[]): void {
    for (const assessment of assessments) {
      for (const variant of assessment.assessmentVariants as AssessmentVariant[]) {
        for (const revision of variant.assessmentRevisions as AssessmentRevision[]) {
          revision.activeThrough = new Date(revision.activeThrough);
          revision.activeFrom = new Date(revision.activeFrom);
        }
      }
    }
  }
}
