<div
  class="flex items-center justify-center"
  [ngClass]="{
    'flex-col': isTextVertical(),
    'flex-col-reverse': textPosition === TextPosition.Top,
    'flex-row-reverse': textPosition === TextPosition.Left
  }">
  <div
    class="loader"
    [ngClass]="{
      'loader-xs': size === SpinnerSize.XSmall,
      'loader-sm': size === SpinnerSize.Small,
      'loader-lg': size === SpinnerSize.Large
    }"></div>
  <span
    *ngIf="text"
    class="mt-2"
    [ngClass]="{
      'text-sm': size === SpinnerSize.Small || size === SpinnerSize.XSmall,
      'text-lg': size === SpinnerSize.Large,
      'mt-2': isTextVertical(),
      'mr-2': textPosition === TextPosition.Left,
      'ml-2': textPosition === TextPosition.Right
    }"
    >{{ text }}</span
  >
</div>
