<div class="dialog-header flex items-center hm-success-dialog">
  <mat-icon class="hm-icon-width mr-2 text-warnLight-500 text-3xl">
    error
  </mat-icon>
  <span class="m-0 ml-2 text-warnLight-500 text-2xl"
    >{{ data.title ?? 'Something Unexpected Happened' }}
  </span>
</div>
<div mat-dialog-content>
  <ng-container
    *ngIf="!data.isException && isSimpleMessage; else nonCustomError">
    <p>{{ data.message }}</p>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close color="primary" class="ml-auto">
    OK
  </button>
</div>

<ng-template #nonCustomError>
  <ng-container *ngIf="isSimpleMessage">
    <p class="text-base">
      We are sorry. {{ data.message }} You did not do anything wrong.
    </p>

    <p class="text-base">
      We have logged this error and the technical team has been automatically
      notified.
    </p>

    <p class="text-base">
      You can try your operation again. If the problem persists, you can also
      contact our Success team at
      <a
        href="mailto:Success@BerkeAssessment.com"
        title="Email Success@BerkeAssessment.com"
        class="underline text-base"
        >Success&#64;BerkeAssessment.com</a
      >.
    </p>
  </ng-container>
  <ng-container *ngIf="!isSimpleMessage">
    <ng-container
      *ngTemplateOutlet="
        $any(data.message);
        context: data.templateContext ?? {}
      "></ng-container>
  </ng-container>
</ng-template>
