import { AssessmentVariantState } from '@app-state/assessment-variant/assessment-variant.state';
import { QueryEntity } from '@datorama/akita';
import { AssessmentVariantStore } from '@app-state/assessment-variant/assessment-variant.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessmentVariant } from '@app-models/assessment/assessment-variant';
import { AssessmentVariantType } from '@app-models/assessment/assessment-variant-type';

@Injectable()
export class AssessmentVariantQuery extends QueryEntity<AssessmentVariantState> {
  constructor(assessmentVariantStore: AssessmentVariantStore) {
    super(assessmentVariantStore);
  }

  /**
   * Gets all variants that belong to an assessment
   * @param assessmentId ID of an assessment
   * @param filter Filter to apply to variant selector
   */
  selectForAssessment(
    assessmentId: number,
    filter?: {
      activeOnly?: boolean;
      types?: AssessmentVariantType[];
    },
  ): Observable<AssessmentVariant[]> {
    // If there is no filter then return all
    if (!filter) return this.selectAll();
    // Otherwise, filter the variants
    return this.selectAll({
      filterBy: entity => {
        if (entity.assessmentId !== assessmentId) return false;
        if (filter.activeOnly && !entity.isActive) return false;
        if (filter.types && !filter.types.includes(entity.variantType))
          return false;

        // All checks passed - return true.
        return true;
      },
    });
  }

  /**
   * Gets a variant that contains the revision
   * @param revisionId ID of the assessment revision
   */
  getForRevision(revisionId: number): AssessmentVariant {
    const variants = this.getAll({
      filterBy: entity =>
        entity.assessmentRevisions.some((id: number) => id === revisionId),
    });

    return variants.length === 1 ? variants[0] : null;
  }

  /**
   * Gets all variants that belong to an assessment
   * @param assessmentId ID of an assessment
   */
  getForAssessment(assessmentId: number): AssessmentVariant[] {
    return this.getAll({
      filterBy: entity => entity.assessmentId === assessmentId,
    });
  }

  /**
   * Returns the count of variants for particular assessment
   * @param assessmentId ID of the assessment
   */
  countForAssessment(assessmentId: number): number {
    return this.getCount(v => v.assessmentId === assessmentId);
  }
}
